import React from 'react'
import Layout from '../components/Layout'
import VehicleRoll from '../components/VehicleRoll'
import {graphql} from "gatsby";

export default class VehiclesIndexPage extends React.Component {
  render() {
    const {location, data} = this.props;
    const reg = new RegExp("/en")
    const en = reg.test(location.pathname);
    const {markdownRemark:{frontmatter: page}} = data
    return (
        <Layout location={location}>
          <div
              className="full-width-image-container margin-top-0"
              style={{
                backgroundImage: `url(${page.image.childImageSharp.fluid.src})`,
                marginTop:0,
                marginBottom:0
              }}
          >
            <section className="hero is-flex" style={{flex:1}}>
              <div className="hero-body">
                <div className="container">
                  <div className="column is-6 is-offset-1">
                    <h1 className="title" style={{color:'white'}}>
                      {en ? page.title_en : page.title}
                    </h1>
                  </div>
                  <div className="column is-8 is-offset-1">
                    <h2 className="subtitle" style={{color:'white'}}>
                      {en ? page.subheading_en : page.subheading}
                    </h2>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section className="section">
            <div className="container">
              <div className="content">
                <VehicleRoll en={en} location={location}/>
              </div>
            </div>
          </section>
        </Layout>
    )
  }
}

export const pageQuery = graphql`
    query VehiclePageTemplateDE {
        markdownRemark(frontmatter: { templateKey: { eq: "vehicles" } }) {
            frontmatter {
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                title
                title_en
                subheading
                subheading_en
            }
        }
    }
`
